<template>	
	<div class="outer-wrapper">

		<Loader :loading="isLoading"/>

		<div v-if="blog != null">
			<BlogHeader 
				v-bind:title="blog.fields.heading" 
				v-bind:img="blog.fields.featured_image"
				sub_title="community stories" 
			/>

			<div class="info">
				<span class="caption date">{{formatDate(blog.fields.show_date * 1000)}}</span>
				<span class="caption read-time">{{blog.fields.read_time}}</span>
			</div>
			<div class="caption org">{{blog.fields.org}}</div>
			<div v-html="blog.fields.intro" class="article">
			</div>
			<div v-html="blog.fields.article" class="article">
			</div>
		</div>
	</div>
</template>

<script>
	import BlogHeader from '@/components/blogs/BlogHeader';
	import contentService from '@/services/contentService.js'
	import helpers from '@/helpers/helpers.js';
	import Loader from '@/components/Loader'
	import { mapState, mapActions } from 'vuex';

	export default {
		components: {
     		BlogHeader,
			Loader
		},
		props: ['title'],
		data() {
			return {
				blog: null,
				isLoading: true,
			}
		},
		computed: {
			...mapState({
				alert: state => state.alert,
			}),
		},
		methods: {
			...mapActions({
				error: "alert/error"
			}),
			getPost: function(id) {
				this.isLoading = true;
				contentService.getPost(id)
					.then((response) => {
						this.blog = response.data.data;
						this.isLoading = false;
					},
					error => {
						this.error("Failed to load...");
						this.isLoading = false;
					});
			},
			formatDate: (date) => helpers.formatDate(date),
		},
		created() {
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					color: 'white',
					route: this.$route.query.redirect ? this.$route.query.redirect : { name: 'Community Stories' },
				},
				right: {
					type: 'none',
				},
				overlayContent: true,
			});
		},
		mounted() {
			this.getPost(this.$route.params.id);
		}
	}
</script>

<style scoped>
	.org {
		color: #118AB2;
		text-align: left;
		margin-left: 14px;
	}
	.info .date {
		color: #000000;
		margin-left: 14px;
	}
	.info .read-time {
		color: #000000;
		float: right;
		margin-right: 14px;
	}
	.info {
		padding-top: 10px;
	}
	.article {
		padding-left: 14px;
		padding-right: 14px;
	}

</style>