<template>
	<div class="blog-header" :style="{'background-image': 'url(' + image + ')'}">
		<div class="outer-wrapper blog-header-inner">
			<div class="title-container">
				<div class="sub-title">{{sub_title || default_sub_title}}</div>
				<div class="title-main">{{title || default_title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import helpers from '@/helpers/helpers.js';
	export default {
		props: ['title', 'sub_title', 'img'],
		data() {
			return { 
				default_title: "no article selected",
				default_sub_title: "no article selected"
			}
		},
		methods: {
			getImage: (img, includesExtension) => helpers.getImage(img, includesExtension),
		},
		computed: {
			image() {
				if(this.img) {
					return this.img
				} else {
					return this.getImage('banners/placeholder.webp', true);
				}
			}
		}
	}
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
	.blog-header-inner {
		height: 100%;
		position: relative;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.9) 100%);
	}
	.blog-header {
		width: 100%;
		height: 487px;
		max-height: 71vh;
		background-size: cover;
		background-position: center;
	}
	.blog-header .title-container {
		padding: 28px;
		padding-left: 14px;
		position: absolute;
		bottom: 3px;
    	text-align: left;
	}
	.blog-header .title-main {
		font-weight: bold;
		font-size: 28px;
		line-height: 36px;
		color: #FFFFFF;
	}
	.blog-header .sub-title {
		font-weight: 600;
		font-size: 12px;
		color: #FFFFFF;
		text-transform: uppercase;
		padding-bottom: 13px;
		font-family: 'Poppins', sans-serif;
	}
	
</style>